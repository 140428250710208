var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","inline":""}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-col',[_c('h2',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('training_session.form.training_location.training_location'))+" ")])])],1),_c('ValidationObserver',{ref:"observer",attrs:{"disabled":!_vm.dialog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_session.form.training_location.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('training_session.form.training_location.name') + '*'},model:{value:(_vm.trainingLocation.name),callback:function ($$v) {_vm.$set(_vm.trainingLocation, "name", $$v)},expression:"trainingLocation.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t(
                  'training_session.form.training_location.type'
                ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-combobox',{staticClass:"flex-grow-0",attrs:{"persistent-hint":"","error-messages":errors,"items":_vm.$appConfig.trainingLocationTypes,"item-value":"@id","item-text":"name","label":_vm.$t('training_session.form.training_location.type') + '*',"outlined":""},nativeOn:{"input":function($event){return (function (e) { return _vm.type = e.target.value; })($event)}},model:{value:(_vm.trainingLocation.type),callback:function ($$v) {_vm.$set(_vm.trainingLocation, "type", $$v)},expression:"trainingLocation.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_session.form.training_location.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('training_session.form.training_location.address') + '*'},model:{value:(_vm.trainingLocation.address),callback:function ($$v) {_vm.$set(_vm.trainingLocation, "address", $$v)},expression:"trainingLocation.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_session.form.training_location.zip_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('training_session.form.training_location.zip_code') + '*'},model:{value:(_vm.trainingLocation.zipCode),callback:function ($$v) {_vm.$set(_vm.trainingLocation, "zipCode", $$v)},expression:"trainingLocation.zipCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_session.form.training_location.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('training_session.form.training_location.city') + '*'},model:{value:(_vm.trainingLocation.city),callback:function ($$v) {_vm.$set(_vm.trainingLocation, "city", $$v)},expression:"trainingLocation.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('training_session.form.training_location.area'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.listAreas,"item-value":"@id","item-text":function (item) { return _vm.$t('form.area', {
                  name: item.name,
                }); },"return-object":"","label":_vm.$t('training_session.form.training_location.area') + '*',"outlined":""},model:{value:(_vm.trainingLocation.area),callback:function ($$v) {_vm.$set(_vm.trainingLocation, "area", $$v)},expression:"trainingLocation.area"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","outlined":"","block":""},on:{"click":function($event){_vm.$emit('close')
                _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")])],1),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","outlined":"","block":""},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.clear'))+" ")])],1),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","block":"","type":"submit","loading":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.$t('btn.create'))+" ")])],1)],1)],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }