





















































































































































































































import { newTrainingLocation } from '@/views/dashboard/trainingLocation/store/trainingLocationForm'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapMutations, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { TrainingLocation } from '@/api/interfaces/traininglocation'

export default defineComponent({
  name: 'CreateTrainingLocation',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      isSubmitting: false,
      trainingLocation: newTrainingLocation() as TrainingLocation,
    }
  },
  computed: {
    ...mapState('area', {
      listAreas: 'list',
    }),
  },
  methods: {
    ...mapActions('trainingLocationForm', {
      save: 'save',
    }),
    ...mapMutations('trainingLocationForm', {
      setTrainingLocation: 'setTrainingLocation',
    }),
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async onSubmit () {
      const formValid = await this.getValidationObserver().validate()
      if (!!formValid as boolean) {
        try {
          this.setTrainingLocation(this.trainingLocation)
          const newTrainingLocation = await this.save(this.trainingLocation)
          this.$emit('input', newTrainingLocation)
        } finally {
          this.$emit('close')
          this.clear()
        }
      }
    },
    clear () {
      this.trainingLocation = newTrainingLocation()
      this.getValidationObserver().reset()
    },
  },
})
